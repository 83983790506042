import { onPrem, TabAppStatus } from "./helperMethods";
import * as adminServicesOnPrem from "../../app/modules/admin/adminServicesOnPrem";
import * as adminServicesAzure from "../../app/modules/admin/adminServicesAzure";

const getAdminServices = () => {
  return onPrem ? adminServicesOnPrem : adminServicesAzure;
};

export enum enumMessageType {
  Success = 1,
  Error = 2,
  Warning = 3,
}

export enum enumUserTabs {
  UserList = 1,
  Profile = 2,
  ResetPassword = 3,
  Settings = 4,
}

export enum enumLoanTabs {
  AddTab1 = 1,
  AddTab2 = 2,
  AddTab3 = 3,
}

export enum enumUserStatus {
  Active = 2,
  InActive = 3,
  Deleted = 4,
}

export enum enumUsrTypes {
  UsrSx = "BBC5BC0E-BCA2-4542-860B-DB0E98A29EF6",
  UsrSv = "4884E96E-9BA0-4322-BB20-BFAB983A7558",
}

export enum enumErrorCodes {
  UnAuthorized = 401,
  NotFound = 404,
}

export enum enumTaxonomies {
  Status = 1,
  UserTypes = 5,
  AppStatusTypes = 8,
  USStates = 39,
  Suffix = 99,
  HousingTypes = 103,
  EmploymentTypes = 108,
  IncomeFrequencyTypes = 128,
  CPITerm = 136,
  WarrantyTerms = 141,
  StreetTypes = 146,
  WarrantyTypes = 291,
  VehicleCondition = 295,
  GapSource = 299,
  TabMenus = 339,
  ContractStates = 365,
  FrontEndFeeTypes = 369,
}

export enum enumApplicantType {
  Applicant,
  CoApplicant,
}

export enum enumAddressType {
  Physical,
  P, //Previous Address
  M, //Mailing Address
}

export enum enumEmploymentType {
  B,
  Pb,
}

export enum enumLoanAddTabs {
  Application = 1,
  Vehicle = 2,
  DealStructure = 3,
  Submit = 4,
}

export enum enumLoanStatus {
  Draft = 1,
  Pending = 2,
  Approved = 3,
  Submitted = 4,
}

export const UserTypeList = [
  { id: "Veros", value: "Veros", checked: true },
  { id: "Dealer", value: "Dealer", checked: false },
];

export const getUserType = (value: any) => {
  switch (value) {
    case "BBC5BC0E-BCA2-4542-860B-DB0E98A29EF6":
      return "Veros";
    case "4884E96E-9BA0-4322-BB20-BFAB983A7558":
      return "Dealer";
    default:
      return "";
  }
};

export const invalidCredentialId = "66194646-A595-4EBE-824D-A49D7AA37D13"; //AUTH_1001
export const invalidMFAId = "E8DFBA87-8088-45CC-9A0D-6A2D375C81FE"; //AUTH_1009
export const accountLockedId = "0738E7B4-C08D-4ABE-BE32-95BC6BD3914C"; //AUTH_1012
export const threeFailedOTPAttemptsId = "9B997071-DA9F-42B0-89D1-1F595EA84AFB"; //AUTH_1014
export const invalidMFAOTPId = "B40215DD-F655-4E63-AF17-9AE67E66EB08"; //AUTH_1010
export const oTPExpiredId = "ED2BDC84-5415-471A-99CF-9203A02C965F"; //AUTH_1011
export const noMobileNumberId = "BACA8B99-6C55-43FC-A9B5-8CEF7AAFA0E2"; //AUTH_1013
export const registeredAsALandLineId = "170989C1-CD67-4DAE-BB07-D615DC41ED0A"; //AUTH_1015
export const googleRecaptchaId = "FF042FB5-4A4E-4833-9593-9E9B50A23EC9";

//Used for Apps list screen
export const allRequest = "E13A53D9-B3FD-44CF-8C16-CE7A66BD4DCC";
export const underWritingRequest = "F032F0DF-7863-4245-8FF1-E9C454B01567";
export const bookedRequest = "7A7D09CE-315B-43B4-81E4-79429C8D8A9B";
export const fundingRequest = "0E939DBD-F89E-4380-BAAB-DE04E11541AD";
export const fundedRequest = "ECAE7C54-B08F-4069-B105-2F7FF6645092";

export const dlrAdmId = "D2CE000B-5214-4908-BAD4-24693F4462CA";

// Excel Constants
export const excelFileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const excelFileExtension = ".xlsx";

//Global Constants

export const defPgLen = 10;
export const defLenMenu = [10, 20, 40, 80, 100];
export const funderContact = "(844) 695-1770";
export const funderEmail = "stips@veroscredit.com";
export const userListQueryCount = 50000;
export const dealerListQueryCount = 1000;
export const formsListQueryCount = 1000;
export const loanAppsQueryCount = 100000;
export const dashboardAppsQueryCount = 100000;
export const idealTimeout = 1200000; // In milliseconds 20*60*1000
export const refreshTime = 900000; //  In milliseconds 15*60*1000 Refresh time always should be less than the API Access/Refresh token expiry time
export const googleTagMgrId = "GTM-KFK2HCRR"; //"G-ZPZ1EDLPTD"; // "G-ZPZ1EDLPTD => DealLane New GTM-5W2SXGH => DealLane Old GTM-M2GH5SZ => Techraq";
export const customErrorMsg = "Required field";
export const invalidVehicleMsg =
  "Some vehicle details are missing. Please check and update the information from Vehicle info edit popup.";
export const unableToProcessMsg =
  "Unable to process the request. Please try again.";
export const monthsErrorMsg = "Months not more than 11";
export const customInvalidAmt = "Invalid value";
export const customLesserdAmt = "Enter lesser value";
export const customInvalidMsg = "Invalid format";
export const vinMinYr = 1992;
export const vinMaxYr = new Date().getFullYear() + 1;
export const rehashAppStatus = ["A", "C", "AA", "AC", "MR"];
export const finalizeDealStatus = [
  TabAppStatus[TabAppStatus.A],
  TabAppStatus[TabAppStatus.AA],
  TabAppStatus[TabAppStatus.C],
  TabAppStatus[TabAppStatus.AC],
];
export const FILE_SIZE = 300 * 1024;
export const defRtTime = 60000; // 60000 In milliseconds 1*60*1000

//export const carletonStates = ["CA", "FL", "TX"];
export let carletonStates = [""];
export const getContractStates = async () => {
  const services = getAdminServices();
  const result = await services.getParentIdData(enumTaxonomies.ContractStates);
  if (result?.data) {
    carletonStates = result?.data.map((item: { name: any }) => item.name);
  }
};

export const rehashPath = "/dealer/rehash/";
export const defaultDisabledColor = "#f9f6f6";

// Used for Apps Count
export const underStatus = [
  TabAppStatus[TabAppStatus.MR],
  TabAppStatus[TabAppStatus.ERR],
  TabAppStatus[TabAppStatus["CB ERR"]],
  TabAppStatus[TabAppStatus.A],
  TabAppStatus[TabAppStatus.AA],
  TabAppStatus[TabAppStatus.C],
  TabAppStatus[TabAppStatus.AC],
];
export const bookedStatus = [TabAppStatus[TabAppStatus.BH]];
export const fundedStatus = [TabAppStatus[TabAppStatus.B]];
export const fundingStatus = [
  TabAppStatus[TabAppStatus.CR],
  TabAppStatus[TabAppStatus.VI],
  TabAppStatus[TabAppStatus.RL],
  TabAppStatus[TabAppStatus.RB],
  TabAppStatus[TabAppStatus.RU],
]; //"RN"
export const carletonContractStatus = [
  //TabAppStatus[TabAppStatus.MR],
  TabAppStatus[TabAppStatus.A],
  TabAppStatus[TabAppStatus.AA],
  TabAppStatus[TabAppStatus.C],
  TabAppStatus[TabAppStatus.AC],
  //TabAppStatus[TabAppStatus.B],
  //TabAppStatus[TabAppStatus["CB ERR"]],
];

export const carContInPrgCompExclStatus = [
  TabAppStatus[TabAppStatus.VI],
  TabAppStatus[TabAppStatus.RL],
  TabAppStatus[TabAppStatus.RB],
  TabAppStatus[TabAppStatus.RU],
  TabAppStatus[TabAppStatus.RN],
  TabAppStatus[TabAppStatus.BH],
  TabAppStatus[TabAppStatus.B],
];

//Disable stips for the status
export const stipDisabledStatus = [
  TabAppStatus[TabAppStatus.AD],
  TabAppStatus[TabAppStatus.D],
];

// Local storage constants

export const userActiveTabKey = "userActiveTabKey";
export const isProfile = "isProfile";
export const selectedUserId = "selectedUserId";
export const isEdit = "isEdit";
export const dealerNameURL = "dealerNameURL"; //TODO: Need to remove in next release
export const dealerIdURL = "dealerIdURL";
export const titleStatus = "titleStatus";
export const dealerDetails = "dealerDetails";
export const appCounts = "appCounts";
export const globalSearchValue = "globalSearchValue";
export const refreshToken = "refreshToken"; //TODO: Need to remove in next release
export const fundingUptAppNo = "fundingUptAppNo";
export const timeToExpired = "timeToExpired"; //TODO: Need to remove in next release
export const selectedLoanType = "selectedLoanType";
export const progressBar = "progressBar";
export const loanAppId = "loanAppId";
export const dealerName = "dealerName";
export const appStatus = "appStatus";
export const haveTabChanges = "haveTabChanges";
export const appEntryEditTab = "appEntryEditTab";
export const analyticUsrMgt = "analyticUsrMgt";
export const dataTblPgLen = "dataTblPgLen";
export const dtTabId = "dtTabId";
export const dtSearchText = "dtSearchText";
export const dtPageNo = "dtPageNo";
export const dtSortColumn = "dtSortColumn";
export const dtSortOrder = "dtSortOrder";
export const isLogin = "isLogin"; //TODO: Need to remove in next release
export const dwat = "dwat";
export const dwret = "dwret";
export const isReFran = "isReFran"; //TODO: Need to remove in next release
export const detDlrId = "detDlrId";
export const reScOutVal = "reScOutVal";
export const reScOut = "reScOut";
export const defRt = "defRt"; //Don't include this in the clear all local storage array, this will store the prev route URL.
export const actAUL = "actAUL"; //TODO: Need to remove in next release
export const brwId = "brwId"; //Don't include this in the clear all local storage array, this will store the prev route URL.
export const slusty = "slusty";
export const isUnCh = "isUnCh";
export const aptSsnTch = "aptSsnTch";
export const coAptSsnTch = "coAptSsnTch";
export const rfTmDt = "rfTmDt";
export const isContFl = "isContFl";
export const conFlPg = "conFlPg";
export const contDlDt = "contDlDt";
export const contDlNoOfPg = "contDlNoOfPg";

// If any new local storage constant added then add the same in this array also
// This array will help to clear all the data(stored in local storage) during logout method
export const localStorageConstants = [
  userActiveTabKey,
  isProfile,
  selectedUserId,
  isEdit,
  dealerNameURL,
  dealerIdURL,
  titleStatus,
  dealerDetails,
  appCounts,
  globalSearchValue,
  refreshToken,
  fundingUptAppNo,
  timeToExpired,
  selectedLoanType,
  progressBar,
  loanAppId,
  dealerName,
  appStatus,
  haveTabChanges,
  appEntryEditTab,
  analyticUsrMgt,
  dataTblPgLen,
  dtTabId,
  dtSearchText,
  dtPageNo,
  dtSortColumn,
  dtSortOrder,
  isLogin,
  dwat,
  dwret,
  isReFran,
  detDlrId,
  reScOutVal,
  reScOut,
  actAUL,
  slusty,
  isUnCh,
  aptSsnTch,
  coAptSsnTch,
  rfTmDt,
  isContFl,
  conFlPg,
  contDlDt,
  contDlNoOfPg,
];

// Regular expressions
export const pwdAllowedSplChar = `!@#$%^*"`;
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const pwdRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*"])[A-Za-z\d!@#$%^*"]{8,20}$/; //Password must be min 8 characters, 1 Special Character, 1 Uppercase,1 Lowercase and 1 Number
// export const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~])(?=.{8,})^/; //Password must be min 8 characters, 1 Special Character, 1 Uppercase,1 Lowercase and 1 Number
//export const pwdRegExp =/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*()])(?=.{8,})/;
export const usPhoneNoFormat =
  /^\(?([0-9]{3})\)?[ . ]?([0-9]{3})[-. ]?([0-9]{4})$/i; // US phone number format (999) 999-9999
export const usPhoneNoFormat_V1 =
  /^\(?[0-9]{3}\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i; // US phone number format 999-999-9999
export const emailRegExp = /\S+@\S+\.\S+/;
// eslint-disable-next-line
export const newEmailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const usSSN = /^[^-]{3}-?[^-]{2}-?[^-]{4}$/; // /^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/;
export const userNameRegExp =
  /^(?=.{4,30}$)(?:[a-zA-Z\d]+(?:(?:\.|_)[a-zA-Z\d])*)+$/i;

export const imagePlaceHolder = [
  "data:image/png;base64,",
  "data:image/jpg;base64,",
  "data:image/jpeg;base64,",
];

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

export const getBoolean = (value: any) => {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
};

export const getTabHeaders = (value: any) => {
  switch (value) {
    case "1":
      return "User Management";
    case "2":
      return "Profile";
    case "3":
      return "Password Reset";
    case "4":
      return "Settings";
    default:
      return "";
  }
};

export enum enumDataTableTabs {
  AllApps = 1,
  UnderWriting = 2,
  Funding = 3,
  Booked = 4,
  Funded = 5,
  OTR = 6,
  WithHold = 7,
  BuyBack = 8,
  UserMgt = 9,
  Ancillary = 10,
}

export const vinWeight = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

export const vinTrans = (value: any) => {
  switch (value) {
    case "a":
      return 1;
    case "b":
      return 2;
    case "c":
      return 3;
    case "d":
      return 4;
    case "e":
      return 5;
    case "f":
      return 6;
    case "g":
      return 7;
    case "h":
      return 8;
    case "j":
      return 1;
    case "k":
      return 2;
    case "l":
      return 3;
    case "m":
      return 4;
    case "n":
      return 5;
    case "p":
      return 7;
    case "r":
      return 9;
    case "s":
      return 2;
    case "t":
      return 3;
    case "u":
      return 4;
    case "v":
      return 5;
    case "w":
      return 6;
    case "x":
      return 7;
    case "y":
      return 8;
    case "z":
      return 9;
    default:
      return 0;
  }
};

export enum enumFinalizeDeal {
  UploadContract = 1,
  OriginalContract = 2,
  UploadStips = 3,
  FinalizeDealContract = 4,
  RehashReviewContract = 5,
  FastFunding = 6,
}

export enum enumFedExGenerate {
  FromFedEx = 1,
  FromRehash = 2,
}

export enum enumRehashSubmitType {
  FromRehash = 1,
  FromFinalDeal = 2,
}

export enum enumDropZoneUploadFile {
  LoanAppDetail = 1,
  FnDlUploadContract = 2,
  FnDlStipUpload = 3,
}

export const excludeRt = [
  "/logout",
  "/auth/login",
  "/auth/forgotpassword",
  "/auth",
  "/error/error404",
  "/dealer/error",
  "/error",
];

export const uploadDocNameReplace = [
  {
    id: 1,
    name: "Copy of Original Contract",
    aliasName: "Copy of Original Contract DLane",
  },
];

export enum enumMFATypes {
  Email = 1,
  SMS = 2,
  Google = 3,
  TempMobileNumber = 4,
}

export const dealerEmployee = "DealerEmployee";
export const dealerAdmin = "DealerAdmin";
export const verosEmployee = "VerosEmployee";
export const verosAdmin = "VerosAdmin";
export const superAdmin = "SuperAdmin";
export const readonly = "Readonly";
export const verosUserTypes = [
  "verosemployee",
  "superadmin",
  "readonly",
  "verosadmin",
];

export const aulRatesMapping = [
  {
    id: 1,
    warrantyTerm: 12,
    amount: 300,
  },
  {
    id: 2,
    warrantyTerm: 24,
    amount: 500,
  },
  {
    id: 3,
    warrantyTerm: 36,
    amount: 700,
  },
];

export enum enumContentTypes {
  "json" = "application/json",
  "formencoded" = "application/x-www-url-formencoded",
}

export enum underWritingPhoneNumbers {
  DefaultNo = "888-224-2975",
  FranchiseNo = "844-695-1769",
  IndependentEaOrCeNo = "888-287-1969",
  IndependentWestNo = "888-224-2975",
}

export enum enumMenuTabs {
  Dashboard = 340,
  AllApps = 341,
  Underwriting = 342,
  Funding = 343,
  Booked = 344,
  Funded = 345,
}

export const contractFileNames = [
  "Original Retail Installment Contract", //Stip Name as per QA, UAT & Prod
  "Copy of Original Retail Installment", //Any Doc Name as per QA
  "Copy of Original Contract", //Any Doc Name as per UAT & Prod
];

export enum customSvgIcons {
  CalculateBlue = "/media/svg/calculate-blue.svg",
  CalculateGrey = "/media/svg/calculate-grey.svg",
  PlaceBlue = "/media/svg/place.svg",
  PlaceGrey = "/media/svg/place-grey.svg",
  DescriptionBlue = "/media/svg/description.svg",
  PictureAsPdfBlue = "/media/svg/picture-as-pdf.svg",
  DescriptionGrey = "/media/svg/description-grey.svg",
}

export enum contractTagInputTypes {
  Numeric = "Numeric",
  Date = "Date",
  Text = "Text",
}

export const replaceContractFileName = [
  {
    id: 1,
    name: "Original Retail Installment Contract",
    replaceName: "Copy of Original Contract",
  },
];

export const replacedStipName = (stipName: string) => {
  const res = replaceContractFileName.filter(
    (names: any) => names.name === stipName
  );
  if (res && res.length > 0) {
    return res[0].replaceName;
  } else {
    return stipName;
  }
};

export const jntLnType = "joint";
export const sinLnType = "single";
export const defaultPageNumber = 0;
export const defaultSortColumn = 9;
export const defaultSortOrder = "desc";

export enum enumContractPopupTypes {
  ItemizedPopup = 1,
  ProductsPopupDirect = 2,
  ProductsPopupIndirect = 3,
}

export enum contractTypes {
  Direct = "Direct",
  Indirect = "Indirect",
}

export enum eSignStatusCompInPrgEnum {
  InProgress = "InProgress",
  Complete = "Complete",
  Failed = "Failed",
}

export const replaceSplCharInDirComp = /[<>&+\\"]/g;
export const apptPrevAddress = "Applicant Previous Address add";
export const apptPrevEmployment = "Applicant Previous Employment add";
export const coApplicantAdd = "CoApplicant add";
export const coApptPrevAddress = "CoApplicant Previous Address add";
export const coApptPrevEmployment = "CoApplicant Previous Employment add";

export enum YesNoEnum {
  YES = "Yes",
  NO = "No",
}

export enum conFlPgEnum {
  AppDetails = "AppDetails",
  Rehash = "Rehash",
}

export enum TrueOrFalseEnum {
  True = "True",
  False = "False",
}

export enum losSourceTypes {
  Defi = "Defi",
  Fuse = "Fuse",
}
