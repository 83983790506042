import { onPremEnv, googleReSiteKey } from "./apiURLs";
import {
  dataTblPgLen,
  dtTabId,
  dtSearchText,
  dtPageNo,
  dtSortColumn,
  dtSortOrder,
  dwat,
  dwret,
  customLesserdAmt,
  customInvalidAmt,
  vinWeight,
  vinTrans,
  enumMessageType,
  defRt,
  uploadDocNameReplace,
  readonly,
  verosUserTypes,
  enumMenuTabs,
} from "./helperContainer";
import { FeatureNotEnabledText } from "./messageContainer";
import { clearStorage, getStorage, setStorage } from "./storageHelper";
import { toastMessage } from "./toastMessage";
import { makeStyles } from "@mui/styles";
import * as commonServicesOnPrem from "../common/commonServicesOnPrem";
import * as commonServicesAzure from "../common/commonServicesAzure";
import * as addLoanServicesOnPrem from "../../app/modules/addLoan/addLoanServicesOnPrem";
import * as addLoanServicesAzure from "../../app/modules/addLoan/addLoanServicesAzure";
import * as adminServicesOnPrem from "../../app/modules/admin/adminServicesOnPrem";
import * as adminServicesAzure from "../../app/modules/admin/adminServicesAzure";
import * as dashboardServicesOnPrem from "../../app/modules/dashboard/dashboardServicesOnPrem";
import * as dashboardServicesAzure from "../../app/modules/dashboard/dashboardServicesAzure";
import * as dealerServicesOnPrem from "../../app/modules/dealer/dealerServicesOnPrem";
import * as dealerServicesAzure from "../../app/modules/dealer/dealerServicesAzure";

export const onPrem = onPremEnv === "true";
const getAdminServices = () => {
  return onPrem ? adminServicesOnPrem : adminServicesAzure;
};

export const useCommonServices = () => {
  return onPrem ? commonServicesOnPrem : commonServicesAzure;
};

export const useAddLoanServices = () => {
  return onPrem ? addLoanServicesOnPrem : addLoanServicesAzure;
};

export const useAdminServices = () => {
  return onPrem ? adminServicesOnPrem : adminServicesAzure;
};

export const useDashboardServices = () => {
  return onPrem ? dashboardServicesOnPrem : dashboardServicesAzure;
};

export const useDealerServices = () => {
  return onPrem ? dealerServicesOnPrem : dealerServicesAzure;
};

export const getInitials = (name: any) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const getRandomColor = () => {
  // var letters = "0123456789ABCDEF";
  // var color = "#";
  // for (var i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * 16)];
  // }
  //return color;
  // Removed the random color selection and adjust to veros color combination
  return "#2572AB";
};

export const createImageFromInitials = (size: any, name: any) => {
  if (name == null) return;
  const color = getRandomColor();
  name = getInitials(name);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;
  context!.fillStyle = "#ffffff";
  context!.fillRect(0, 0, size, size);
  context!.fillStyle = `${color}50`;
  context!.fillRect(0, 0, size, size);

  context!.fillStyle = color;
  context!.textBaseline = "middle";
  context!.textAlign = "center";
  context!.font = `${size / 1.8}px Roboto`;
  context!.fillText(name, size / 2.0, size / 1.8);

  return canvas.toDataURL();
};

export const globalSaveAnalytics = async (
  userId: string,
  role: string,
  pageURL: string,
  appNo: string,
  dealerId: string,
  dealerName: string,
  remarks: string,
  email: string,
  navDealerId?: string,
  navDealerName?: string
) => {
  const services = getAdminServices();
  var bodyFormData = new FormData();
  bodyFormData.append("UserId", userId);
  bodyFormData.append("RoleType", role);
  bodyFormData.append("Remarks", remarks);
  bodyFormData.append("PageURL", pageURL);
  bodyFormData.append("DealerName", dealerName);
  bodyFormData.append("DealerId", dealerId);
  bodyFormData.append("AppNo", appNo);
  bodyFormData.append("Email", email);
  bodyFormData.append("NavDealerId", navDealerId ?? "");
  bodyFormData.append("NavDealerName", navDealerName ?? "");
  await services.saveAnalytics(bodyFormData);
};

export const usCurrencyFormat = (num: any) => {
  if (num) {
    const numCheck = num.toString().replaceAll(/,/g, "");
    const val = Number(numCheck);
    return "$" + val.toFixed(2).replaceAll(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "";
  }
};

export const setPageLength = async (length: any) => {
  const services = getAdminServices();
  var bodyFormData = new FormData();
  bodyFormData.append("PageLength", length);
  await services.saveUserSetting(bodyFormData);
  setStorage(dataTblPgLen, length);
};

export const resetDataTableValues = async (
  tabId: number,
  search: string,
  page: number,
  sortColumn: number,
  sortOrder: string
) => {
  setStorage(dtTabId, tabId);
  setStorage(dtSearchText, search);
  setStorage(dtPageNo, page);
  setStorage(dtSortColumn, sortColumn);
  setStorage(dtSortOrder, sortOrder);
};

export const getAuthToken = () => {
  return getStorage(dwat);
};

export const getRefreshToken = () => {
  return getStorage(dwret);
};

export const checkAndUpdateToken = async () => {
  const services = getAdminServices();
  var bodyFormData = new FormData();
  bodyFormData.append("AccessToken", getAuthToken()!);
  bodyFormData.append("RefreshToken", getRefreshToken()!);
  const res = await services.getUpdatedToken(bodyFormData);
  if (res && res.data && res.data.status === 200) {
    clearStorage(dwat);
    clearStorage(dwret);
    setStorage(dwret, res.data.data.refresh_token);
    setStorage(dwat, res.data.data.access_token.token);
    return true;
  } else {
    return false;
  }
};

export const errMoneyFormat = (field: any) => {
  let errorMsg = "";
  if (field) {
    field = field.toString();

    const testVal = field.includes(".");
    if (testVal === true) {
      const count: number = field.substring(0, field.indexOf(".")).length;
      if (count === 0) {
        if (field.length > 10) {
          errorMsg = customLesserdAmt;
        }
      } else if (count > 10) {
        errorMsg = customLesserdAmt;
      } else {
        if (field.substring(field.indexOf(".")).length === 1) {
          errorMsg = customInvalidAmt;
        }
      }
      return errorMsg;
    } else {
      return errorMsg;
    }
  } else {
    return errorMsg;
  }
};

export const numberCleanupForNegative = (value: string) => {
  const input = value ? value.toString() : "";
  const output = input
    ? Number(
        input
          ? input.replaceAll("$", "").replaceAll(",", "").replaceAll("%", "")
          : 0
      )
    : 0;

  return output;
};

export const numberCleanup = (value: string) => {
  const input = value ? value.toString() : "";
  const output = input
    ? Number(
        input
          ? input
              .replaceAll("$", "")
              .replaceAll(",", "")
              .replaceAll("-", "")
              .replaceAll("%", "")
          : 0
      )
    : 0;

  return output;
};

export const stringToNumberIfFailReturnString = (value: any) => {
  const input = value ? value.toString() : "";
  const num = Number(
    input
      .trim()
      .replaceAll("$", "")
      .replaceAll(",", "")
      .replaceAll("-", "")
      .replaceAll("%", "")
  );
  return !isNaN(num) ? num : value;
};

export const stringToNumberIfFailReturnNaN = (value: any) => {
  const input = value ? value.toString() : "";
  const num = Number(
    input
      .trim()
      .replaceAll("$", "")
      .replaceAll(",", "")
      .replaceAll("-", "")
      .replaceAll("%", "")
  );
  return input.length > 0 && !isNaN(num) ? num : NaN;
};

export const stringToNumberIfFailReturnZero = (value: any) => {
  const input = value ? value.toString() : "";
  const num = Number(
    input
      .trim()
      .replaceAll("$", "")
      .replaceAll(",", "")
      .replaceAll("-", "")
      .replaceAll("%", "")
  );
  return !isNaN(num) ? num : 0;
};

export const nullCheck = (value: string) => {
  return value ? value : "";
};

export const validateVIN = (value: string) => {
  let sum = 0;
  if (value.length !== 17) {
    return false;
  }

  for (let i = 0; i < value.length; i++) {
    const curVal = value[i];

    if (!Number(curVal)) {
      sum += vinTrans(curVal.toLowerCase()) * vinWeight[i];
    } else {
      sum += Number(curVal) * vinWeight[i];
    }
  }

  const checkDigit = sum % 11;

  if (checkDigit === 10) {
    if (value[8] === "x" || value[8] === "X") {
      return true;
    } else {
      return false;
    }
  } else {
    if (checkDigit === Number(value[8])) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkForReadOnlyUser = (userType: string) => {
  let isRes: boolean = false;
  if (userType === readonly) {
    isRes = true;
    toastMessage(enumMessageType.Error, FeatureNotEnabledText);
  }
  return isRes;
};

export const constStipDocBody = (
  documentFiles: any,
  appNo: string,
  uploadFileName: string,
  userId: string,
  dlrId: string,
  isDltCommitment: string,
  isContractReq: string
) => {
  var bodyFormData = new FormData();
  bodyFormData.append("Documentfiles", documentFiles);
  bodyFormData.append("AppNo", appNo);
  bodyFormData.append("FilePrefix", uploadFileName?.trim());
  bodyFormData.append("UserId", userId);
  bodyFormData.append("DealerId", dlrId);
  bodyFormData.append("IsDlrCommitment", isDltCommitment);
  bodyFormData.append("IsContractReq", isContractReq);
  return bodyFormData;
};

export const bindDropDown = async (dropType: number) => {
  const services = getAdminServices();
  const result = await services.getParentIdData(dropType);
  return result?.data;
};

export const clearDefRoute = async () => {
  clearStorage(defRt);
};

export const replaceComma = (commaVal: any) => {
  if (commaVal) {
    const outPut = commaVal.toString().replaceAll(/,/g, "");
    return outPut;
  } else {
    return "0.00";
  }
};

export const replaceUploadDocName = (fileName: string) => {
  const res = uploadDocNameReplace.filter(
    (names: any) => names.name === fileName
  );
  if (res.length > 0) {
    return res[0].aliasName;
  } else {
    return fileName;
  }
};

export const formatUSMobileNumber = (phoneNumber: string) => {
  let formatMob = phoneNumber;
  formatMob = formatMob
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll(" ", "")
    .replaceAll("-", "");

  if (formatMob.length === 10) {
    formatMob = `(xxx) xxx-${formatMob.slice(6, 10)}`;
    return formatMob;
  }
  return "";
};

export const getLoginUserRole = (email: string) => {
  if (email?.toLowerCase().includes("veroscredit")) {
    return "Veros";
  } else {
    return "Dealer";
  }
};

export const trimLoginUserType = (role: string) => {
  if (verosUserTypes.includes(role?.toLowerCase())) {
    return "Veros";
  } else {
    return "Dealer";
  }
};

export const getMimetype = async (signature: string) => {
  switch (signature) {
    case "FFD8FFDB":
    case "FFD8FFE0":
    case "FFD8FFE1":
    case "FFD8FFE2":
      return "image/jpeg";

    case "89504E47":
      return "image/png";

    case "47494638":
      return "image/gif";

    case "25504446":
      return "application/pdf";

    //Match the string that starts with 424D
    case signature.match(/^424D/)?.input:
      return "image/bmp";

    case "00018667":
      return "image/heic";

    default:
      return "Invalid format";
  }
};

export const checkMIME = async (img: any) => {
  // we need to get the raw bytes
  const buffer = await img.arrayBuffer();
  // each entry of array should contain 8 bits
  const bytes = new Uint8Array(buffer);

  let allBytes: string[] = [];
  bytes.forEach((byte) => {
    allBytes.push(byte.toString(16));
  });

  const hex = allBytes.join("").toUpperCase();
  const type = await getMimetype(hex.substring(0, 8));
  return type;
};

export enum TabAppStatus {
  AR = 9,
  IP = 10,
  AA = 11,
  AD = 12,
  MR = 13,
  A = 14,
  C = 15,
  D = 16,
  CR = 17,
  RB = 18,
  B = 19,
  VI = 20,
  UR = 21,
  FR = 22,
  RN = 23,
  I = 24,
  ERR = 25,
  "CB ERR" = 26,
  RA = 27,
  RD = 28,
  NB = 29,
  X = 30,
  RU = 31,
  CAN = 32,
  DR = 33,
  RL = 34,
  BH = 35,
  EV = 36,
  RS = 37,
  AC = 38,
}

export const DefaultMenuTabRoute = (menuTabs: any) => {
  let defRoute = "/dealer/allapps";
  if (menuTabs && menuTabs?.length > 0) {
    const tabId = menuTabs[0];
    if (tabId === enumMenuTabs.Dashboard) {
      defRoute = "/dashboard/dashboard";
    } else if (tabId === enumMenuTabs.Underwriting) {
      defRoute = "/dealer/appsUnderwriting";
    } else if (tabId === enumMenuTabs.Funding) {
      defRoute = "/dealer/appsFunding";
    } else if (tabId === enumMenuTabs.Booked) {
      defRoute = "/dealer/appsBooked";
    } else if (tabId === enumMenuTabs.Funded) {
      defRoute = "/dealer/appsFunded";
    }
  }
  return defRoute;
};

export const ConstructRecaptcha = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${googleReSiteKey}`;
  script.addEventListener("load", handleRecaptcha);
  document.body.appendChild(script);
};

export const truncateEmail = (email: string) => {
  if (email && email.length > 14) {
    return `${email.substring(0, 13)}...`;
  } else {
    return email;
  }
};

const handleRecaptcha = () => {};

export const addInvisibleSpaceToEmail = (email: any) => {
  const atIndex = email.indexOf("@");
  if (atIndex !== -1) {
    const firstPart = email.slice(0, atIndex);
    const secondPart = email.slice(atIndex);
    return `${firstPart}\u200B${secondPart}`;
  }
  return email;
};

export const toggleSwitch = makeStyles(() => ({
  customSwitch: {
    width: "43px !important",
    height: "19px !important",
    padding: "0px !important",
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 3,
      transitionDuration: "300ms",
      transform: "translateX(2px)",
      color: "#36A3F7 !important",
      "&.Mui-checked": {
        transform: "translateX(22px)",
        "& + .MuiSwitch-track": {
          border: "1px solid lightgrey",
          backgroundColor: "white !important",
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 13,
      height: 13,
      boxShadow: "none !important",
    },
    "& .MuiSwitch-track": {
      border: "1px solid lightgrey",
      borderRadius: 26 / 2,
      backgroundColor: "white !important",
      opacity: 1,
    },
  },
  directToggleArea: {
    "& .MuiSwitch-input": {
      left: "-20% !important",
    },
  },
  indirectToggleArea: {
    "& .MuiSwitch-input": {
      left: "-180% !important",
    },
  },
}));

export const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.value) {
    let value = e.target.value.replace(/^\//, "");
    value = value.replace(/[^0-9/]/g, "");
    value = value.replace(/\/+/g, "/");
    const parts = value.split("/");
    if (parts.length > 3) {
      value = parts.slice(0, 3).join("/");
    }

    if (parts.length === 1) {
      if (parts[0].length > 1 && parseInt(parts[0]) > 12) {
        value = parts[0].slice(0, 1) + "/" + parts[0].slice(1);
      }
      if (parts[0].length > 2) {
        value = parts[0].slice(0, 2) + "/" + parts[0].slice(2);
      }
    } else if (parts.length === 2) {
      if (parts[1].length > 1 && parseInt(parts[1]) > 31) {
        value = parts[0] + "/" + parts[1].slice(0, 1) + "/" + parts[1].slice(1);
      }
      if (parts[1].length > 2) {
        value = parts[0] + "/" + parts[1].slice(0, 2) + "/" + parts[1].slice(2);
      }
      // else {
      //   value = parts[0] + "/" + parts[1];
      // }
    } else if (parts.length === 3) {
      if (parts[2].length > 4) {
        value = parts[0] + "/" + parts[1] + "/" + parts[2].slice(0, 4);
      } else {
        value = parts[0] + "/" + parts[1] + "/" + parts[2];
      }
    }
    if (value.length > 10) {
      value = value.substring(0, 10);
    }

    e.target.value = value;
  }
};

export const formatDate = (date: any) => {
  if (!date) return null;
  const d = new Date(date);
  d.setHours(0, 0, 0, 0); // Set to local midnight
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(d.getDate()).padStart(2, "0")}T00:00:00.0000000`;
};

export const minFPDSelectableDate = new Date();
minFPDSelectableDate.setDate(minFPDSelectableDate.getDate() + 30);

export const maxFPDSelectableDate = new Date();
maxFPDSelectableDate.setDate(maxFPDSelectableDate.getDate() + 44);

export const minFPDDateFormatted = formatDate(minFPDSelectableDate);
export const maxFPDDateFormatted = formatDate(maxFPDSelectableDate);

export const mxRecordValidation = async (email: string): Promise<boolean> => {
  const domain = email.split("@")[1];
  try {
    const response = await fetch(
      `https://dns.google/resolve?name=${domain}&type=MX`
    );
    const data = await response.json();
    return data?.Answer && data?.Answer?.length > 0;
  } catch (error) {
    return true;
  }
};
